'use strict'

module.exports = ($) ->
  return if ($.inviewport)

  $.belowthefold = (element, settings) ->
    fold = $(window).height() + $(window).scrollTop()
    fold <= $(element).offset().top - settings.threshold

  $.abovethetop = (element, settings) ->
    top = $(window).scrollTop()
    top >= $(element).offset().top + $(element).height() - settings.threshold

  $.rightofscreen = (element, settings) ->
    fold = $(window).width() + $(window).scrollLeft()
    fold <= $(element).offset().left - settings.threshold

  $.leftofscreen = (element, settings) ->
    left = $(window).scrollLeft()
    left >= $(element).offset().left + $(element).width() - settings.threshold

  $.inviewport = (element, settings) ->
    !$.rightofscreen(element, settings) && !$.leftofscreen(element, settings) && !$.belowthefold(element, settings) && !$.abovethetop(element, settings)

  $.extend($.expr[':'], {
    "below-the-fold": (a, i, m) ->
      $.belowthefold a, threshold: 0
    "above-the-top": (a, i, m) ->
      $.abovethetop a, threshold: 0
    "left-of-screen": (a, i, m) ->
      $.leftofscreen a, threshold: 0
    "right-of-screen": (a, i, m) ->
      $.rightofscreen a, threshold: 0
    "in-viewport": (a, i, m) ->
      $.inviewport a, threshold: 0
  })

  ###*
  # jQuery function to retrieve all classes from a selector
  #
  # @mixin jQuery.fn.getClasses
  # @returns {Array} classlist
  #
  # @example
  # $(document.body).getClasses()
  ###
  jQuery.fn.getClasses = ->
    ca = @attr('class')
    classlist = []
    if ca and ca.length and ca.split
      ca = jQuery.trim(ca)        # strip leading and trailing spaces
      ca = ca.replace(/\s+/g, ' ') # remove double spaces
      classlist = ca.split(' ')

    classlist

  jQuery.fn.getEvents = (n = 0) ->
    $._data($(this)[n], 'events')

'use strict'

triggerEvent = (eventName, state, title, URL) ->
  event = document.createEvent('HTMLEvents')
  event.initEvent(eventName, true, true)
  Object.assign(event, { eventName, state, title, URL })
  window.dispatchEvent(event)

###*
# Add window.history events
# onpushstate, onreplacestate and onchangestate.
###
initHistoryStates = (history) ->
  { pushState, replaceState } = history

  history.pushState = (state, title, URL) ->
    result = pushState.apply(history, arguments)
    triggerEvent('pushstate', state, title, URL)
    triggerEvent('changestate', state, title, URL)
    result

  history.replaceState = (state, title, URL) ->
    result = replaceState.apply(history, arguments)
    triggerEvent('replacestate', state, title, URL)
    triggerEvent('changestate', state, title, URL)
    result

  window.addEventListener 'popstate', (e) ->
    triggerEvent('changestate', e.state)

module.exports = initHistoryStates

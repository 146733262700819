'use strict';

/*
* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
* Bind the global BAST compliant Cookie.js to the window object
* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
*/
const girlScoutsCookies = require('groupon-domain-cookie').fromDocument;

window.Cookie = girlScoutsCookies(document);

/**
 *
 */
const removeDuplicateCookies = (exports.removeDuplicates = () => {
  document.cookie
    .split('; ')
    .map(cookie => {
      return cookie.split('=')[0]; // get cookie names
    })
    .filter((name, index, self) => {
      return index !== self.indexOf(name); // filter duplicates
    })
    .forEach(name => {
      Cookie.unset(name); // remove domain-specific duplicates with Cookie.unset()
    });
});

/*
* Clean duplicate cookies on page load
*/

// FIXME: remove at the end of project Bast
removeDuplicateCookies();

'use strict'

# In jQuery, any $(document).ready callback that throws an error blocks others.
# Instead, capture these exceptions, log them to the console, and keep running.

module.exports = ($) ->

  $.fn.ready = do (proceed = $.fn.ready) ->

    # our new $(document).ready, taking the presumed-buggy callback "unsafe":
    (unsafe) ->

      # runs that callback inside a try-catch that also logs errors helpfully
      safely = ->
        try
          return unsafe.apply(this, arguments)
        catch e
          msg = 'Exception in a jQuery.ready callback: '
          if window.console and typeof console.error is 'function'
            console.error msg, e
          else
            err = new Error msg + e.message
            err.original = e
            setTimeout ( -> throw err ), 0
        return

      # hands over our safer callback to jQuery's "native" $.fn.ready handler
      proceed safely

'use strict'

module.exports = ($) ->
  PlaceholderField = $.fn.PlaceholderField = ->
    return this if PlaceholderField.hasNativePlaceholderSupport()

    @each -> PlaceholderField.initPlaceholder $(this)

  PlaceholderField.hasNativePlaceholderSupport = ->
    _.all [
      document.createElement 'input'
      document.createElement 'textarea'
    ], (elem) -> elem.placeholder?

  inputEmpty = ($input) -> /^\s*$/.test $input.val()

  PlaceholderField.initPlaceholder = ($input) ->
    focusin = ->
      $input.val('').removeClass('prompting') if defaultPromptEnabled($input)

    do focusout = ->
      setPlaceholder($input) if inputEmpty($input)

    $input.on {focusin, focusout}

  setPlaceholder = ($input) ->
    $input.addClass('prompting')
    $input.val $input.attr('placeholder') ? $input.attr('title')

  defaultPromptEnabled = ($input) ->
    sansWhitespace = (val) -> val.replace /\s/g, ''
    placeholder = $input.attr('placeholder') || $input.attr('title') || ''
    sansWhitespace($input.val()) is sansWhitespace(placeholder)

  PlaceholderField

'use strict'

window._ = require 'underscore'
require '../../vendor/md5'
require '../../core/cookie-monster'
require '../../../../core/temp/vendor/modernizr'# FIXME: remove 4 major

I18n = require 'node_modules/grapefruit/lib/I18n'
dataSet = document.head.dataset
options = {
  country: dataSet.country
  locale: dataSet.locale
  lang: dataSet.lang
}
window.I18n = new I18n(options)

# the reason cart has been moved here is that hoganJS only exists in the core file
require '../../vendor/eventPause-custom'
require 'jquery.finger'
initHistoryEvent = require './history_events'

initHistoryEvent(window.history)
